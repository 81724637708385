<template>
    <v-menu
    v-model="menu"
    class="menu"
    :close-on-content-click="false"
    transition="slide-y-transition"
    style="z-index: 9999;"
    offset-y bottom
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" elevation="0" class="ml-3 ml-md-1">
        <template v-if="countUnread > 0">
          <v-badge :content="countUnread" overlap color="#8061C2">
            <v-icon color="rgb(87 75 113)" size="25">
              mdi-bell
            </v-icon>
          </v-badge>
        </template>
        <template v-else>
          <v-icon color="rgb(87 75 113)" size="25">
            mdi-bell
          </v-icon>
        </template>
      </v-btn>
    </template>

    <v-card class="p-4 rounded-xl">
      <v-card-title class="pa-4 py-3 title-modal text-left" color="#8061C2">
        NOTIFICACIONES
        <v-icon color="white" size="20" class="ml-3">
          mdi-bell-ring
        </v-icon>

        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="markAllAsRead">
              <v-icon color="white" size="20">mdi-email-open-multiple-outline</v-icon>
            </v-btn>
          </template>
          <p class="text-center mb-0">Marcar todas</p>
          <p class="text-center mb-0">como leídas</p>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="deleteNotifyAll">
              <v-icon color="white" size="25">mdi-delete-outline</v-icon>
            </v-btn>
          </template>
          <span>Eliminar todas</span>
        </v-tooltip>
      </v-card-title>


        <v-tabs v-model="tab" color="#8061C2" class="round-tab ml-0 mt-0" height="50" grow >
          <v-tab v-for="(tab, index) in tabs" :key="index" class="custom-tab ">
            <v-badge :content="getBadgeContent(index)" color="#8061C2" floating v-if="getBadgeContent(index) > 0" class="tell-icon">
              {{ tab }}
            </v-badge>
            <template v-else>
              {{ tab }}
            </template>
          </v-tab>

          <v-tab-item>
            <div v-if="loading" class="loading-overlay">
              <v-progress-circular indeterminate color="#8e84c0"></v-progress-circular>
            </div>
            <v-card class="notifications-container d-flex flex-column mt-0 pt-3 rounded-xl" elevation="3">
              <div class="notifications-content" >
                <template v-if="badgeContents[0] > 0">
                  <transition-group name="fade" tag="div">
                    <v-row no-gutters v-for="notify in displayedNotificationsAll" :key="notify.id" class="notify-row">
                      <v-col cols="3" class="d-flex justify-center align-center">
                        <v-avatar size="50" class="clickable-avatar">
                          <v-img :src="notify.data.harmony.logo_path" v-if="notify.data.harmony.logo_path"></v-img>
                          <v-img :src="require('../../../assets/illustrations/no-photo.jpg')" v-else></v-img>
                        </v-avatar>
                      </v-col>
                      <v-col cols="7" class="d-flex flex-column justify-center">
                        <h1 class="title text-left mt-3">Bajo rendimiento</h1>
                        <p class="description text-left">
                          El harmony <a @click="goToStatistics(notify.data.harmony)">{{ notify.data.harmony.name }}</a>
                          ha bajado un {{ notify.data.percentage }}% su rendimiento en la última semana.
                        </p>
                        <p class="text-hour text-left">
                          {{ formatDate(notify.created_at) }}
                        </p>
                      </v-col>
                      <v-col class="d-flex flex-column align-center justify-center">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="#8e84c0" size="20" v-bind="attrs" v-on="on"
                              @click="deleteNotify(notify.id)">mdi-delete-outline</v-icon>
                          </template>
                          <p class="text-center mb-0">Eliminar</p>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </transition-group>
                  <v-btn v-if="allNotifications.length > 4" class="mx-auto mt-0 mustNotifys" text @click="showMore = !showMore">
                  <v-icon left>{{ showMore ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                  <span>{{ showMore ? 'Mostrar menos' : 'Mostrar más' }}</span>
                </v-btn>
                </template>
                <template v-else>
                  <div class="notification-container d-flex justify-center flex-column align-center">
                    <v-img :src="require('../../../assets/illustrations/Notebook-bro.svg')"
                      class="notification-image"></v-img>
                    <p class="text-center">No hay notificaciones</p>
                  </div>
                </template>
              </div>
            </v-card>
          </v-tab-item>


          <!-- Reads -->
          <v-tab-item>
            <div v-if="loading" class="loading-overlay">
              <v-progress-circular indeterminate color="#8e84c0"></v-progress-circular>
            </div>
            <v-card class="notifications-container d-flex flex-column mt-0 pt-3 rounded-xl" elevation="3">
              <div class="notifications-content">
                <template v-if="badgeContents[1] > 0">
                  <transition-group name="fade" tag="div">
                  <v-row no-gutters v-for="notify in displayedNotificationsRead" :key="notify.id" class="notify-row">
                    <v-col cols="3" class="d-flex justify-center align-center">
                      <v-avatar size="50" class="clickable-avatar">
                        <v-img :src="notify.data.harmony.logo_path" v-if="notify.data.harmony.logo_path"></v-img>
                        <v-img :src="require('../../../assets/illustrations/no-photo.jpg')" v-else></v-img>
                      </v-avatar>
                    </v-col>
                    <v-col cols="7" class="d-flex flex-column justify-center">
                      <div>
                        <h1 class="title text-left  mt-3">Bajo rendimiento</h1>
                        <p class="description text-left">
                          El harmony <a @click="goToStatistics(notify.data.harmony)">{{ notify.data.harmony.name }}</a>
                          ha bajado un
                          {{ notify.data.percentage }}% su rendimiento en la última semana.
                        </p>
                        <p class="text-hour text-left">
                         {{ formatDate(notify.created_at) }}
                        </p>
                      </div>
                    </v-col>
                    <v-col class="d-flex flex-column align-center justify-center">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="#8e84c0" size="20" v-bind="attrs" v-on="on"
                            @click="deleteNotify(notify.id)">mdi-delete-outline</v-icon>
                        </template>
                        <p class="text-center mb-0">Eliminar</p>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </transition-group>
                  <v-btn v-if="readNotifications.length > 4" class="mx-auto mt-0  mustNotifys" text @click="showMore = !showMore">
                    <v-icon left>{{ showMore ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                    <span>{{ showMore ? 'Mostrar menos' : 'Mostrar más' }}</span>
                  </v-btn>
                </template>
                <template v-else>
                  <div class="notification-container d-flex justify-center flex-column align-center">
                    <v-img :src="require('../../../assets/illustrations/Notebook-bro.svg')"
                      class="notification-image"></v-img>
                    <p class="text-center">No hay notificaciones</p>
                  </div>
                </template>
              </div>
            </v-card>
          </v-tab-item>

          <!-- unReads -->
          <v-tab-item>
            <div v-if="loading" class="loading-overlay">
              <v-progress-circular indeterminate color="#8e84c0"></v-progress-circular>
            </div>
            <v-card class="notifications-container d-flex flex-column mt-0 pt-3 rounded-xl" elevation="3">
              <div class="notifications-content">
                <template v-if="badgeContents[2] > 0">
                <transition-group name="fade" tag="div">
                  <v-row no-gutters v-for="notify in displayedNotificationsUnread" :key="notify.id" class="notify-row">

                    <v-col cols="3" class="d-flex justify-center align-center">
                      <v-avatar size="50" class="clickable-avatar">
                        <v-img :src="notify.data.harmony.logo_path" v-if="notify.data.harmony.logo_path"></v-img>
                        <v-img :src="require('../../../assets/illustrations/no-photo.jpg')" v-else></v-img>
                      </v-avatar>
                    </v-col>

                    <v-col cols="7" class="d-flex flex-column justify-center">
                      <div>
                        <h1 class="title text-left  mt-3">Bajo rendimiento</h1>
                        <p class="description text-left">
                          El harmony <a @click="goToStatistics(notify.data.harmony)">{{ notify.data.harmony.name }}</a>
                          ha bajado un {{ notify.data.percentage }}% su rendimiento en la última semana.
                        </p>
                        <p class="text-hour text-left">
                         {{ formatDate(notify.created_at) }}
                        </p>
                      </div>
                    </v-col>

                    <v-col class="d-flex flex-column align-center justify-center">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="#8e84c0" size="20" v-bind="attrs" v-on="on"
                            @click="markReadNotify(notify.id)">mdi-email-open-outline</v-icon>
                        </template>
                        <p class="text-center mb-0">Marcar</p>
                        <p class="text-center mb-0">como leída</p>
                      </v-tooltip>
                    </v-col>

                  </v-row>
                </transition-group>
                  <v-btn v-if="unreadNotifications.length > 4" class="mx-auto mt-0  mustNotifys" text @click="showMore = !showMore">
                    <v-icon left>{{ showMore ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                    <span>{{ showMore ? 'Mostrar menos' : 'Mostrar más' }}</span>
                  </v-btn>
                </template>
                <template v-else>
                  <div class="notification-container d-flex justify-center flex-column align-center">
                    <v-img :src="require('../../../assets/illustrations/Notebook-bro.svg')"
                      class="notification-image"></v-img>
                    <p class="text-center">No hay notificaciones</p>
                  </div>
                </template>
              </div>
            </v-card>
          </v-tab-item>

        </v-tabs>
    </v-card>
  </v-menu>
</template>

<script>
import axios from '@/axios.js';
export default {
  data() {
    return {
      menu: false,
      notifysUnRead: 0,
      countUnread: 0,
      allNotifications: [],
      readNotifications: [],
      unreadNotifications: [],
      tabs: ['Todas', 'Leídas', 'No leídas'],
      badgeContents: [0, 0, 0],
      tab: null,
      showMore: false,
      loading: false,
    };
  },

  computed: {
    displayedNotificationsAll() {
      return this.showMore ? this.allNotifications : this.allNotifications.slice(0, 4);
    },
    displayedNotificationsRead() {
      return this.showMore ? this.readNotifications : this.readNotifications.slice(0, 4);
    },
    displayedNotificationsUnread() {
      return this.showMore ? this.unreadNotifications : this.unreadNotifications.slice(0, 4);
    },
  },

  created() {
    this.getNotify();
  },

  methods: {
    async getNotify() {
      this.loading = true;
      try {
        const response = await axios.get('/get-user-notifications');
        this.countUnread = response.data.countUnread;
        this.allNotifications = response.data.allNotifications;
        this.readNotifications = response.data.readNotifications;
        this.unreadNotifications = response.data.unreadNotifications;
        this.badgeContents = [
          response.data.allNotifications.length,
          response.data.readNotifications.length,
          response.data.countUnread
        ];
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    formatDate(date) {
    const now = new Date();
    const createdAt = new Date(date);
    const diffInSeconds = Math.floor((now - createdAt) / 1000);

    const intervals = {
      año: 31536000,
      mes: 2592000,
      día: 86400,
      hora: 3600,
      minuto: 60,
      segundo: 1,
    };

    for (const [unit, secondsInUnit] of Object.entries(intervals)) {
      const interval = Math.floor(diffInSeconds / secondsInUnit);
      if (interval >= 1) {
        return `hace ${interval} ${unit}${interval > 1 ? 's' : ''}`;
      }
    }
    return 'hace unos segundos';
    },


    getBadgeContent(index) {
      return this.badgeContents[index];
    },

    markRead() {
      this.loading = true;
      // Aquí puedes agregar la lógica para marcar como leída
      setTimeout(() => {
        this.loading = false;
      }, 2000); // Simulación de tiempo de espera
    },

    async deleteNotify(id) {
      this.loading = true;
      try {
        const response = await axios.post('/delete-notification', { id });
        if (response.data.success) {
          this.getNotify();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async deleteNotifyAll() {
      this.loading = true;
      try {
        const response = await axios.post('/delete-notification', { id: 'all' });
        if (response.data.success) {
          this.getNotify();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async markReadNotify(id) {
      this.loading = true;
      try {
        const response = await axios.post('/mark-read-notification', { id });
        if (response.data.success) {
          this.getNotify();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async markAllAsRead() {
      this.loading = true;
      try {
        const response = await axios.post('/mark-read-notification', { id: 'all' });
        if (response.data.success) {
          this.getNotify();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    goToStatistics(item) {
      const harmonyUrl = `${window.location.origin}/panel/admin/stats?id=${item.id}&user_id=${item.user_id}&name=${encodeURIComponent(item.name)}`;
      window.open(harmonyUrl, '_blank');
    },

  },
};
</script>

<style scoped>
.title {
  font-size: 12px !important;
  font-weight: 600 !important;
   line-height: 1.2rem !important;
  color: #8E84C0 !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.description {
  font-size: 11px !important;
  color: #737373 !important;
  margin: 0px !important;
  max-width: 300px !important;
}
.text-hour {
  font-size: 10px !important;
  color: #737373 !important;
  margin-bottom: 3px !important;
}

.image-notify {
  width: 40px;
  height: 40px;
  background-position: contain !important;
}

.notifications-container {
  max-height: 400px;
  min-height: 300px;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.notify-row {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 2px;
}

.title-modal {
  background-color: #8061C2;
  color: white;
  font-size: 15px !important;
  font-weight: 600 !important;
}

.custom-tab {
  text-transform: none;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 8px;
}

.round-tab {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}


.v-application a {
  color: #8E84C0 !important;
  text-decoration: none;
  font-weight: 600;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.mustNotifys {
font-size: 14px;
font-weight: 500;
color: #8E84C0;
}

::v-deep .tell-icon .v-badge__badge{
  font-size: 10px;
  height: 17px;
}

@media (width > 500px) {
  .menu {
    min-width: 410px !important;
  }
}

@media (width < 500px) {
  .menu {
    min-width: 200px !important;
  }
}
</style>

<style lang="scss" scoped>
.v-menu__content {
  border-radius: 16px !important;
}

::v-deep .round-tab .v-slide-group__prev.v-slide-group__prev--disabled,
::v-deep .round-tab .v-slide-group__next.v-slide-group__next--disabled {
  display: none !important;
}

@media (width > 500px) {
  .v-menu__content {
    min-width: 410px !important;
  }
}

@media (width < 500px) {
  .v-menu__content {
    min-width: 200px !important;
  }
}
</style>